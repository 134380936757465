<template>
    <div class="card p-2" style="min-height: calc(100vh - 180px)">
      <div class="d-flex flex-wrap justify-content-between align-item-center">
        <h3>Order & Sales Management</h3>
        <div class="gx-1">
          <button
              style="min-width: 64px;"
              class="btn btn-outline-secondary waves-effect waves-float waves-light me-1"
          >Register
          </button>
          <button
              style="min-width: 64px;"
              @click="navigateToAddPage"
              class="btn btn-primary waves-effect waves-float waves-light me-1"
           >New Order
          </button>
        </div>
      </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                    for="colFormLabel"
                    class="col-form-label"
                >
                 From Date(Invoice)
                </label>
                <input
                    v-model="invoiceStartDate"
                    type="date"
                    class="form-control date-mask"
                    placeholder="From"
                >
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label
                    for="colFormLabel"
                    class="col-form-label"
                >
                 To Date(Invoice)
                </label>
                <input
                    v-model="invoiceEndDate"
                    type="date"
                    class="form-control date-mask"
                    placeholder="From"
                >
              </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <label class="form-label">Select Sales Person</label>
                    <AsyncSelect
                        placeholder="Select Sales Person"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Customer: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect
                        placeholder="Select Customer"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :additional-option="additionalOption"
                        :option-selected="onChangeContact"
                        :key="contactRenderKey"
                    />
                </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                <label class="form-label">Order No</label>
                <input type="text"
                       class="form-control invoice-edit-input"
                       placeholder="Order No"
                       v-model="orderNo">
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                <label class="form-label">Invoice No</label>
                <input type="text"
                       class="form-control invoice-edit-input"
                       placeholder="Invoice No"
                       v-model="invoiceNo">
              </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                    <label class="form-label">Status</label>
                    <v-select
                        placeholder="Select Status"
                        v-model="selectedOrderStatus"
                        :options="orderStatuses"
                        label="name"
                        :reduce="option => option.value"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-1">
                    <label class="form-label">Invoice Status</label>
                    <v-select
                        placeholder="Select Invoice Status"
                        v-model="selectedInvoiceStatus"
                        :options="invoiceStatuses"
                        label="name"
                        :reduce="option => option.value"
                    />
                </div>
            </DateQuerySetter>

        </div>
        <div class="col-12 px-2 mt-2">
            <ListTable
                :purchase="purchaseList" 
                route_name="sales-order-edit"
                :fromApproval="fromApproval"
            />
        </div>
        <div class="px-2">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :companyRoles="companyRoles"
            :isFilterable="true"
        />
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed, watch  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/order-sales/ListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleCrm from '@/services/modules/crm/crm'
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
let contactProfile = ref(null)

const route = useRoute()
const singleSR = ref(null);
const singleDealer = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const { fetchSalesOrderList, fetchOrderStatus } = handlePurchase()
const { fetchContactProfiles } = handleContact();
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchAllDealers, fetchAllSR } = handleCrm();
const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: '',
    roles: 'customer,lead'
});
const companyRoles = ref(null);
const invoiceStatuses = ref([]);
const orderStatuses = ref([]);
const selectedInvoiceStatus = ref(null);
const selectedOrderStatus = ref(null);
const invoiceStartDate = ref(null)
const invoiceEndDate = ref(null)
const orderNo = ref(null)
const invoiceNo = ref(null)

const onChangeContact = (e) => {
    //TODO: contact profile id
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})
const fromApproval = computed(() => {
    return route.name === 'sales-order-approval';
})

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalDealerQuery.value = {'sale_representative_id': newValue.id}
    }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

function navigateToAddPage() {
    const query = Object.assign({}, route.query)
    query.type = 'approval';

    router.push({name: 'sales-order-add', params: route.params, query: query})
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    if(!startDate.value && !endDate.value) return query
    if(contactProfile.value) query += '&contact_profile_id='+contactProfile.value.id
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(page.value) query += '&page='+page.value
    if(selectedOrderStatus.value) query += '&status='+selectedOrderStatus.value
    if(selectedInvoiceStatus.value) query += '&invoice_status='+selectedInvoiceStatus.value
    if(invoiceStartDate.value) query += '&invoice_start_date='+invoiceStartDate.value
    if(invoiceEndDate.value) query += '&invoice_end_date='+invoiceEndDate.value
    if(orderNo.value) query += '&order_no='+orderNo.value
    if(invoiceNo.value) query += '&invoice_no='+invoiceNo.value
    query += '&offset='+offset.value
    return query
}

function onClickGo() {
    fetchSalesOrderList(getQuery())
    .then(res => {
        if(res.status) {
            purchaseList.value = res.date.data
            setPagination(res.date)
        }else {
            purchaseList.value = []
            store.commit("resetPagination")
        }
        
    })
}

const getOrderStatus = async () => {
    const res = await fetchOrderStatus('?company_id=' + companyId.value);
    if (res.status) {
        invoiceStatuses.value = res.data.filter(item => ['created', 'pending'].includes(item.value))
        orderStatuses.value = res.data.filter(item => ['pending', 'approved'].includes(item.value))
    }
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    onClickGo()
}

onMounted(() => {
    onClickGo()
    getOrderStatus()
    getCompanyRoles()
})

</script>

<style scoped>
.mt-tpp-1 {
    margin-top: 1%;
}
.filter-label {
    padding-right: 0.5rem !important;
}
</style>
